import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
/*
 * @name: 张梦琳
 * @test: test font
 * @Date: 2021-02-02 17:02:01
 * @FilePath: \web_bcrm\src\mixin\checkBank.js
 * @msg:
 * @param:
 * @return:
 */
import Cleave from 'cleave.js';
import Banks from '@/assets/bank.json';
var checkBank = {
  methods: {
    $_bankCardMounted: function $_bankCardMounted() {
      // 监听input挂载的钩子
      new Cleave('.bankCard .el-input__inner', {
        blocks: [4, 4, 4, 4, 4, 4]
      });
    },
    $_checkBankNum: function $_checkBankNum(bankCard, bankCardName, formName) {
      var _this = this;
      var opts = {
        method: 'GET'
      };
      // 支付宝的银行验证地址
      var bankNum = formName ? this[formName][bankCard] : this[bankCard];
      var trimBankNum = bankNum.toString().replace(/\s*/g, '');
      var url = 'https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=' + trimBankNum + '&cardBinCheck=true';
      fetch(url, opts).then(function (res) {
        return res.json();
      }).then(function (e) {
        if (e.stat === 'ok' && e.validated === false) {
          _this.$notify({
            title: '警告',
            message: '暂未查到此银行卡信息，请手动输入银行名称。',
            type: 'warning',
            duration: 2000
          });
          if (formName) {
            _this[formName][bankCardName] = undefined;
          } else {
            _this[bankCardName] = undefined;
          }
        } else {
          var bankName_ = e.bank;
          if (formName) {
            _this[formName][bankCardName] = Banks[bankName_];
          } else {
            _this[bankCardName] = Banks[bankName_];
          }
        }
      }).catch(function (error) {});
    }
  }
};
export default checkBank;