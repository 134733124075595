var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container teachers" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-top": "20px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            staticClass: "marginTop",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleAdd },
                          },
                          [_vm._v("新建")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            staticClass: "marginTop",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleUpdateBtn },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            staticClass: "marginTop",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleDelBtn },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["移交"],
                                expression: "['移交']",
                              },
                            ],
                            staticClass: "marginTop",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleAgentChange },
                          },
                          [_vm._v("移交")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-upload",
                          {
                            staticClass: "dib marginTop",
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              "http-request": _vm.TeacherExcel,
                              "show-file-list": false,
                              action: "action",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["信息导入"],
                                    expression: "['信息导入']",
                                  },
                                ],
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("信息导入")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["下载信息模板"],
                                expression: "['下载信息模板']",
                              },
                            ],
                            staticClass: "marginTop",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.DownExcel },
                          },
                          [_vm._v("下载信息模板")]
                        ),
                        _vm._v(" "),
                        _c("my-export-excel", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["导出"],
                              expression: "['导出']",
                            },
                          ],
                          staticClass: "btnse marginLeft marginTop",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            filename: "我的客户列表",
                            list: _vm.multipleSelection,
                            "label-arr": _vm.exportOptions,
                            "value-arr": _vm.exportValueArr,
                          },
                        }),
                        _vm._v(" "),
                        _c("display-settings", {
                          staticClass: "ml10 filter-item marginTop",
                          attrs: {
                            "display-arr": _vm.displayOptions,
                            "display-value": _vm.displayValue,
                            "default-value": _vm.defaultDisplayValue,
                            cname: _vm.title,
                          },
                          on: {
                            "update:displayValue": function ($event) {
                              _vm.displayValue = $event
                            },
                            "update:display-value": function ($event) {
                              _vm.displayValue = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 5 } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "input-with-select r marginTop",
                            attrs: { size: "small", placeholder: "请选择" },
                            on: { change: _vm.SelecthandleChange },
                            model: {
                              value: _vm.follow_type,
                              callback: function ($$v) {
                                _vm.follow_type = $$v
                              },
                              expression: "follow_type",
                            },
                          },
                          _vm._l(_vm.followOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        id: "customerTable",
                        data: _vm.list,
                        fit: "",
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                      on: { "selection-change": _vm.selsChange },
                    },
                    [
                      _c("el-table-column", { attrs: { type: "selection" } }),
                      _vm._v(" "),
                      _vm.displayValue.includes("组织部门")
                        ? _c("el-table-column", {
                            attrs: {
                              label: "组织部门",
                              prop: "structure_name",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("姓名")
                        ? _c("el-table-column", {
                            attrs: { label: "姓名", width: "200" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890FF" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTodetails(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.name)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              518921654
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("主要社会职务")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "主要社会职务", prop: "post" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("简介")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "简介", prop: "remark" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("研究领域")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "研究领域", prop: "field" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("性别")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "性别", prop: "sex" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("生日")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "生日", prop: "birthday" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("手机号")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "手机号", prop: "phone" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("微信")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "微信", prop: "weixin" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("邮箱")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "邮箱", prop: "email" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("备注")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "备注", prop: "note" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("主要课程")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "主要课程", prop: "course" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("课程大纲")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "课程大纲", prop: "outline" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("课程亮点")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "课程亮点", prop: "bright_spot" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("年度排班表")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "年度排班表", prop: "schedule" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("专业素养")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "专业素养", prop: "attainment" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("学员满意度")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "学员满意度", prop: "pleased" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(_vm._s(scope.row.pleased) + "星"),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              665163811
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("常住地")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "常住地", prop: "home_area" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("饮食")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "饮食", prop: "food" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("住宿")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "住宿", prop: "stay" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("午休")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "午休", prop: "noon_break" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("开户银行")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "开户银行", prop: "bank" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("银行卡号")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "银行卡号", prop: "card_number" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("课酬金额")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "课酬金额", prop: "money" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("收款时间")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "收款时间", prop: "collect_time" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("添加时间")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "添加时间", prop: "create_time" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("最后跟进日期")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "最后跟进日期",
                              prop: "follow_time",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("下次提醒时间")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "下次提醒时间",
                              prop: "next_follow",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("创建人")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "创建人", prop: "create_name" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("师资分类")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "师资分类", prop: "type" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("收款方式")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "收款方式", prop: "collect_type" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("照片")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "照片" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("跟进次数")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "跟进次数" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.FollowupBtn(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "跟进(" +
                                              _vm._s(scope.row.follow_up) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3350360351
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("状态")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: { label: "状态" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: _vm._f("statusFilter")(
                                              row.status
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.status - 1 == 0
                                                ? "进行中"
                                                : "无效"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1992512888
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                ],
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    "current-page": _vm.query.page,
                    totalPage: _vm.query.total,
                    pageSize: _vm.query.pagesize,
                  },
                  on: {
                    handleSizeChange: _vm.handleSizeChange,
                    handleCurrentChange: _vm.handleCurrentChange,
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.textMap[_vm.dialogStatus],
                      visible: _vm.dialogFormVisible,
                      "close-on-click-modal": false,
                      width: "50%",
                      top: "0vh",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogFormVisible = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "editForm",
                        staticStyle: { "margin-left": "100px" },
                        attrs: {
                          model: _vm.editForm,
                          "label-width": "120px",
                          "lable-position": "left",
                          rules: _vm.rules,
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { md: 24, lg: 11 } },
                              [
                                _c("div", { staticClass: "blue mr10" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "f18 b mb30 g3" }, [
                                  _vm._v("基本信息"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "头像", prop: "img" } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          "http-request": _vm.Imageup,
                                          action:
                                            "https://crmapi.ternarysystem.com/",
                                          "show-file-list": false,
                                          accept:
                                            ".gif, .jpg, .jpeg, .png, .GIF, .JPG, .PNG",
                                          "before-upload":
                                            _vm.beforeAvatarUpload,
                                        },
                                      },
                                      [
                                        _vm.imageUrl
                                          ? _c("img", {
                                              staticClass: "avatar",
                                              attrs: { src: _vm.imageUrl },
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                            }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "教师名称", prop: "name" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "请输入教师名称" },
                                      model: {
                                        value: _vm.editForm.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "name", $$v)
                                        },
                                        expression: "editForm.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "部门",
                                      prop: "structure_id",
                                    },
                                  },
                                  [
                                    _vm.editForm.type === 1
                                      ? _c("el-input", {
                                          attrs: { disabled: true },
                                          model: {
                                            value: _vm.editForm.structure_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editForm,
                                                "structure_id",
                                                $$v
                                              )
                                            },
                                            expression: "editForm.structure_id",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-cascader", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        placeholder: "请选择部门",
                                        options: _vm.bumenoptions,
                                        props: { value: "id", label: "name" },
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.editForm.structure_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "structure_id",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.structure_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "师资分类", prop: "type" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        staticStyle: {
                                          width: "400px",
                                          height: "50px",
                                        },
                                        attrs: { placeholder: "请选择" },
                                        model: {
                                          value: _vm.editForm.type,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.editForm, "type", $$v)
                                          },
                                          expression: "editForm.type",
                                        },
                                      },
                                      _vm._l(
                                        _vm.Teacheroptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "主要社会职务",
                                      prop: "post",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        type: "textarea",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value: _vm.editForm.post,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "post", $$v)
                                        },
                                        expression: "editForm.post",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "简介", prop: "remark" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        type: "textarea",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value: _vm.editForm.remark,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "remark", $$v)
                                        },
                                        expression: "editForm.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "主要研究领域",
                                      prop: "field",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        type: "textarea",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value: _vm.editForm.field,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "field", $$v)
                                        },
                                        expression: "editForm.field",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "性别", prop: "sex" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "0" },
                                        model: {
                                          value: _vm.editForm.sex,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.editForm, "sex", $$v)
                                          },
                                          expression: "editForm.sex",
                                        },
                                      },
                                      [_vm._v("男")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: "1" },
                                        model: {
                                          value: _vm.editForm.sex,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.editForm, "sex", $$v)
                                          },
                                          expression: "editForm.sex",
                                        },
                                      },
                                      [_vm._v("女")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "生日", prop: "birthday" },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        type: "date",
                                        placeholder:
                                          "选择日期 或输入yyyy-MM-dd",
                                        format: "yyyy 年 MM 月 dd 日",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.editForm.birthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "birthday",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.birthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "电话号码", prop: "phone" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "请输入电话号码" },
                                      model: {
                                        value: _vm.editForm.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "phone", $$v)
                                        },
                                        expression: "editForm.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "微信", prop: "weixin" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "请输入微信" },
                                      model: {
                                        value: _vm.editForm.weixin,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "weixin", $$v)
                                        },
                                        expression: "editForm.weixin",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "邮箱", prop: "email" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "请输入邮箱" },
                                      model: {
                                        value: _vm.editForm.email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "email", $$v)
                                        },
                                        expression: "editForm.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { ref: "基本信息" }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { md: 24, lg: 23 } },
                              [
                                _c("div", { staticClass: "blue mr10" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "f18 b mb30 g3" }, [
                                  _vm._v("课程信息"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "主讲课题",
                                      prop: "course",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "主讲课题" },
                                      model: {
                                        value: _vm.editForm.course,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "course", $$v)
                                        },
                                        expression: "editForm.course",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "课程大纲",
                                      prop: "outline",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "课程大纲" },
                                      model: {
                                        value: _vm.editForm.outline,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "outline", $$v)
                                        },
                                        expression: "editForm.outline",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "课程亮点",
                                      prop: "bright_spot",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: "课程亮点",
                                      },
                                      model: {
                                        value: _vm.editForm.bright_spot,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "bright_spot",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.bright_spot",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "年度排班表",
                                      prop: "schedule",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      attrs: {
                                        type: "textarea",
                                        autosize: "",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value: _vm.editForm.schedule,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "schedule",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.schedule",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { md: 24, lg: 23 } },
                              [
                                _c("div", { staticClass: "blue mr10" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "f18 b mb30 g3" }, [
                                  _vm._v("师资测评"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "专业素养",
                                      prop: "attainment",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.editForm.attainment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "attainment",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.attainment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { md: 24, lg: 23 } },
                              [
                                _c("div", { staticClass: "blue mr10" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "f18 b mb30 g3" }, [
                                  _vm._v("接待助手"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "常住地",
                                      prop: "home_area",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      model: {
                                        value: _vm.editForm.home_area,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "home_area",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.home_area",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "饮食", prop: "food" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      model: {
                                        value: _vm.editForm.food,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "food", $$v)
                                        },
                                        expression: "editForm.food",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "住宿", prop: "stay" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      model: {
                                        value: _vm.editForm.stay,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "stay", $$v)
                                        },
                                        expression: "editForm.stay",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "午休",
                                      prop: "noon_break",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      model: {
                                        value: _vm.editForm.noon_break,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "noon_break",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.noon_break",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注", prop: "note" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "400px" },
                                      attrs: { type: "textarea" },
                                      model: {
                                        value: _vm.editForm.note,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "note", $$v)
                                        },
                                        expression: "editForm.note",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "附件", prop: "file_id" } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          "http-request": _vm.uploadSectionFile,
                                          "show-file-list": true,
                                          action: "action",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("点击上传")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { md: 24, lg: 23 } },
                              [
                                _c("div", { staticClass: "blue mr10" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "f18 b mb30 g3" }, [
                                  _vm._v("课酬信息"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "银行卡号",
                                      prop: "card_number",
                                    },
                                    on: {
                                      "hook:mounted": _vm.$_bankCardMounted,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "bankCard",
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "请输入银行卡账号",
                                        maxlength: 23,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.$_checkBankNum(
                                            "card_number",
                                            "bank",
                                            "editForm"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.editForm.card_number,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "card_number",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.card_number",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "银行名称", prop: "bank" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "待识别",
                                      },
                                      model: {
                                        value: _vm.editForm.bank,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "bank", $$v)
                                        },
                                        expression: "editForm.bank",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "课酬金额", prop: "money" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: { placeholder: "0.00" },
                                      model: {
                                        value: _vm.editForm.money,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "money", $$v)
                                        },
                                        expression: "editForm.money",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "收款方式",
                                      prop: "collect_type",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      model: {
                                        value: _vm.editForm.collect_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "collect_type",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.collect_type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "收款时间",
                                      prop: "collect_time",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: {
                                        width: "400px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        type: "date",
                                        placeholder:
                                          "选择日期 或输入yyyy-MM-dd",
                                        format: "yyyy 年 MM 月 dd 日",
                                        "value-format": "yyyy-MM-dd",
                                      },
                                      model: {
                                        value: _vm.editForm.collect_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "collect_time",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.collect_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { ref: "课酬信息" }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            nativeOn: {
                              click: function ($event) {
                                _vm.dialogFormVisible = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _vm.dialogStatus == "create"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.createData },
                              },
                              [_vm._v("添加")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.updateData },
                              },
                              [_vm._v("修改")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.followUpDialog
                  ? _c(
                      "el-dialog",
                      {
                        attrs: { title: "跟进", visible: _vm.followUpDialog },
                        on: {
                          "update:visible": function ($event) {
                            _vm.followUpDialog = $event
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { md: 24, lg: 12, span: 12 } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "grid-content bg-purple" },
                                  [
                                    _c(
                                      "el-form",
                                      {
                                        ref: "followUpForm",
                                        staticStyle: { "margin-left": "50px" },
                                        attrs: {
                                          "label-position": "right",
                                          "label-width": "120px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "is-required",
                                            attrs: {
                                              label: "主题:",
                                              prop: "subject",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 3,
                                              },
                                              model: {
                                                value: _vm.tempFollowup.subject,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tempFollowup,
                                                    "subject",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tempFollowup.subject",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "is-required",
                                            attrs: {
                                              label: "类型:",
                                              prop: "type",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "filter-item",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: _vm.tempFollowup.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.tempFollowup,
                                                      "type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tempFollowup.type",
                                                },
                                              },
                                              _vm._l(
                                                _vm.typeOptionsArr,
                                                function (e, i) {
                                                  return _c("el-option", {
                                                    key: i,
                                                    attrs: {
                                                      label: e,
                                                      value: e,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "is-required",
                                            attrs: { label: "下次提醒日期:" },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                type: "datetime",
                                                placeholder: "选择日期",
                                                format: "yyyy 年 MM 月 dd 日",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              model: {
                                                value:
                                                  _vm.tempFollowup.next_time,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tempFollowup,
                                                    "next_time",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tempFollowup.next_time",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "30px",
                                            },
                                            attrs: { label: "附件:" },
                                          },
                                          [
                                            _c(
                                              "el-upload",
                                              {
                                                staticClass: "avatar-uploader",
                                                attrs: {
                                                  "http-request":
                                                    _vm.uploadSectionFile,
                                                  "show-file-list": true,
                                                  action: "action",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "small",
                                                      type: "primary",
                                                    },
                                                  },
                                                  [_vm._v("点击上传")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "400px",
                                    overflow: "auto",
                                  },
                                },
                                [
                                  _c("div", [_c("h4", [_vm._v("跟进记录")])]),
                                  _vm._v(" "),
                                  _vm.follows_up.length != 0
                                    ? _c(
                                        "el-timeline",
                                        _vm._l(
                                          _vm.follows_up,
                                          function (activity, index) {
                                            return _c(
                                              "el-timeline-item",
                                              {
                                                key: index,
                                                attrs: { placement: "top" },
                                              },
                                              [
                                                _c(
                                                  "el-card",
                                                  {
                                                    attrs: {
                                                      "body-style": {
                                                        padding: "15px",
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "g6 lh18",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("类型："),
                                                        ]),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              activity.type
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "g6 lh18",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("主题 ："),
                                                        ]),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              activity.subject
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    activity.file_info !== ""
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "g6 lh18 blueFont poi",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            附件 ：\n                                            "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "blueFont",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    activity
                                                                      .file_info
                                                                      .file_name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "g6 lh18",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "下次提醒时间 ：" +
                                                            _vm._s(
                                                              activity.next_time
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "h3",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v("暂无跟进记录")]
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.followUpDialog = false
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addFollowUp()
                                  },
                                },
                              },
                              [_vm._v("确定")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("staff-select", {
              ref: "staffSelect",
              attrs: {
                "disable-arr": _vm.staffDisableArr,
                radio: _vm.radio,
                condition: [0],
                visible: _vm.staffSelectVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.staffSelectVisible = $event
                },
                staffSelectConfirm: _vm.staffSelectConfirm,
              },
              model: {
                value: _vm.staffResult,
                callback: function ($$v) {
                  _vm.staffResult = $$v
                },
                expression: "staffResult",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }