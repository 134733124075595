import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyExportExcel from '@/components/MyExportExcel/teacherindex';
//单选部门人员
import StaffSelect from '@/components/StaffSelect/StaffSelectone';
import Head from '@/components/head/index';
import checkBankMixin from '@/mixin/checkBank.js'; // 识别银行
import { GetTeacherList, DelTeacherList, AddTeacherList, UpdataTeacherList, TransferTeacher, AttachmentS, AttachmentW, importTeacherExcel, addTeacherFollow, TeacherFollow, GetOneTeacherList } from '@/api/ceshi_xiugai/Myteacher';
import { getGroupList, getStructureListArr } from '@/api/PublicAPI';
import DisplaySettings from '@/components/DisplaySettings/index';
var defaultDisplayValue = ['组织部门', '姓名', '跟进次数', '性别', '手机号', '主要社会职务', '简介', '研究领域', '学员满意度', '午休', '创建人', '状态', '课酬金额', '生日', '师资分类'];
var fieldsdata = ['name', 'structure_id', 'post', 'remark', 'field', 'sex', 'birthday', 'phone', 'weixin', 'email', 'img', 'file', 'note', 'course', 'outline', 'bright_spot', 'schedule', 'attainment', 'pleased', 'home_area', 'food', 'stay', 'noon_break', 'bank', 'card_number', 'money', 'collect_type', 'collect_time', 'type', 'create_id', 'status', 'client_type', 'client_id'];
import axios from 'axios';
import { getTokens } from '@/utils/auth';
export default {
  name: 'Myteacher',
  components: {
    DisplaySettings: DisplaySettings,
    StaffSelect: StaffSelect,
    Head: Head,
    MyExportExcel: MyExportExcel
  },
  data: function data() {
    var _rules;
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      tempId: '',
      labelPosition: 'right',
      exportOptions: ['组织部门', '姓名', '跟进次数', '性别', '手机号', '主要社会职务', '简介', '研究领域', '学员满意度', '午休', '创建人', '状态', '课酬金额', '生日', '师资分类'],
      multipleSelection: [],
      //导出，客户信息
      exportValueArr: ['structure_name', 'name', 'follow_up', 'sex', 'phone', 'post', 'remark', 'field', 'pleased', 'noon_break', 'create_name', 'status', 'money', 'birthday', 'type'],
      //导出，标签文字
      filename: '',
      bookType: 'xlsx',
      href: 'http:localhost:8080/api/clientserver.php/Teacher/downloadStandardExcel',
      title: '师资管理',
      //图片
      imageUrl: '',
      type: 0,
      count: {},
      //部门名称
      bumen: '',
      bumenoptions: [],
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      Teacher: '',
      Teacheroptions: [],
      searchData: {},
      follow_type: 0,
      followOptions: [{
        label: '全部',
        value: 0
      }, {
        label: '今天应联系',
        value: 1
      }, {
        label: '今日新增',
        value: 2
      }, {
        label: '今日跟进过',
        value: 3
      }, {
        label: '一周跟进过',
        value: 4
      }, {
        label: '一周未跟进',
        value: 5
      }, {
        label: '30天未跟进',
        value: 6
      }, {
        label: '60天未跟进',
        value: 7
      }, {
        label: '90天未跟进',
        value: 8
      }],
      list: [],
      //师资列表
      editForm: {
        structure_id: '',
        structure_name: '',
        name: '',
        post: '',
        remark: '',
        field: '',
        sex: '',
        birthday: '',
        phone: '',
        weixin: '',
        email: '',
        img: '',
        note: '',
        course: '',
        outline: '',
        bright_spot: '',
        schedule: '',
        attainment: '',
        pleased: '',
        home_area: '',
        food: '',
        stay: '',
        noon_break: '',
        bank: '',
        card_number: '',
        money: '',
        collect_type: '',
        collect_time: '',
        create_time: '',
        follow_time: '',
        next_follow: '',
        create_name: '',
        follow_up: '',
        type: '',
        status: '',
        file_id: '',
        img_id: ''
      },
      rules: (_rules = {
        structure_id: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        post: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        birthday: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }]
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_rules, "post", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "remark", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "field", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "sex", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "type", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "bumen", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "type", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "money", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }]), "phone", [{
        required: true,
        message: '请输入手机号码',
        trigger: ['blur']
      }, {
        pattern: /^1[3456789]\d{9}$/,
        message: '请输入正确的手机号码',
        trigger: ['blur']
      }]), "email", [{
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: ['blur']
      }]), _defineProperty(_rules, "name", [{
        required: true,
        message: '必填项',
        trigger: 'blur'
      }])),
      //操作数组
      Alltableoptions: [],
      //编辑新增弹窗
      dialogFormVisible: false,
      //新增编辑
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '新建'
      },
      //显示设置
      displayOptions: [],
      defaultDisplayValue: defaultDisplayValue,
      displayValue: defaultDisplayValue,
      fields: undefined,
      //移交
      staffSelectVisible: false,
      staffResult: [],
      staffDisableArr: [],
      shareDialog: false,
      radio: false,
      checkList: [],
      checkListdata: [],
      bumen_id: '',
      //新建跟进
      followUpDialog: false,
      typeOptionsArr: [],
      //新建跟进类型
      tempFollowup: {
        tid: '',
        subject: '',
        type: '',
        next_time: '',
        file_id: '',
        t_name: ''
      },
      follows_up: [] //跟进列表
    };
  },
  //状态
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'info'
      };
      return statusMap[status];
    }
  },
  mixins: [checkBankMixin],
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.GetTList();
      this.getStructure();
      // 11111111
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.tb.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
    } else {}
  },
  methods: {
    handleAvatarSuccess: function handleAvatarSuccess() {},
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this2 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this2.GetTList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this3.GetTList();
      });
    },
    //获取全部列表
    GetTList: function GetTList() {
      var _this4 = this;
      GetTeacherList({
        page: this.query.page,
        pagesize: this.query.pagesize,
        status: this.type,
        follow_type: this.follow_type
      }).then(function (respomse) {
        _this4.list = respomse.data.teacher_list;
        _this4.query.total = respomse.data.count.total_num;
        _this4.count = respomse.data.count;
        _this4.Teacheroptions = respomse.data.teacher_type;
        if (_this4.type == 1) {
          _this4.query.total = respomse.data.count.having_num;
        } else if (_this4.type == 2) {
          _this4.query.total = respomse.data.count.ending_num;
        }
        _this4.exportOptions = [];
        _this4.displayOptions = [];
        _this4.exportValueArr = [];
        for (var item in respomse.data.teacher_fields) {
          _this4.exportOptions.push(respomse.data.teacher_fields[item]);
          _this4.exportValueArr.push(item);
          _this4.displayOptions.push(respomse.data.teacher_fields[item]);
        }
        _this4.displayValue = respomse.data.fields;
      });
      //部门
    },
    getStructure: function getStructure() {
      var _this5 = this;
      getStructureListArr().then(function (respomse) {
        _this5.bumenoptions = respomse.data;
      });
    },
    //新建师资
    handleAdd: function handleAdd() {
      this.bumen = [];
      this.editForm = {};
      if (this.Alltableoptions.length !== 0) {
        this.Alltableoptions = [];
      }
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
    },
    // 新增
    createData: function createData() {
      var _this6 = this;
      this.$refs.editForm.validate(function (valid) {
        if (valid) {
          _this6.$confirm('确认提交吗？', '提示', {}).then(function () {
            var end = _this6.editForm.structure_id[_this6.editForm.structure_id.length - 1];
            _this6.editForm.structure_id = end;
            _this6.editForm.sex = _this6.editForm.sex.toString(); //性别
            //状态
            _this6.editForm.type.toString();
            var para = Object.assign({}, _this6.editForm);
            AddTeacherList(para).then(function (res) {
              _this6.$notify({
                message: '提交成功',
                type: 'success'
              });
              _this6.dialogFormVisible = false;
              _this6.$nextTick(function () {
                _this6.GetTList();
              });
            });
          }).catch(function (e) {
            // 打印一下错误
          });
        }
      });
    },
    //编辑
    handleUpdateBtn: function handleUpdateBtn() {
      var _this7 = this;
      if (this.Alltableoptions.length !== 0) {
        if (this.Alltableoptions.length == 1) {
          this.dialogStatus = 'update';
          this.dialogFormVisible = true;
          GetOneTeacherList({
            id: this.tempId
          }).then(function (res) {
            _this7.editForm = Object.assign({}, res.data);
            if (res.data.img_info.url == undefined) {
              _this7.imageUrl = ' https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png';
            } else {
              _this7.imageUrl = process.env.VUE_APP_BASE_API2 + res.data.img_info.url;
            }
            if (res.data.sex === '男') {
              _this7.editForm.sex = '0';
            } else {
              res.data.sex = '1';
            }
          });
        } else {
          this.$message('只能选择一个进行编辑');
        }
      } else {
        this.$message('请选择一个进行编辑');
      }
    },
    //提交修改
    updateData: function updateData() {
      var _this8 = this;
      if (this.Alltableoptions.length !== 0) {
        this.dialogStatus = 'edit';
        // const para = Object.assign({}, this.editForm);
        this.editForm.id = this.editForm.id;
        var end = this.editForm.structure_id[this.editForm.structure_id.length - 1];
        this.editForm.structure_id = end;
        UpdataTeacherList(this.editForm).then(function (res) {
          _this8.$notify({
            message: '提交成功',
            type: 'success'
          });
          _this8.$nextTick(function () {
            _this8.GetTList();
          });
          _this8.dialogFormVisible = false;
        }).catch(function () {
          _this8.dialogFormVisible = false;
        });
      }
    },
    //删除
    handleDelBtn: function handleDelBtn() {
      var _this9 = this;
      if (this.Alltableoptions.length !== 0) {
        if (this.Alltableoptions.length == 1) {
          this.$confirm('确定删除吗?', '提示', {
            type: 'warning'
          }).then(function () {
            var ids = _this9.Alltableoptions.map(function (item) {
              return item.id;
            }).join();
            DelTeacherList({
              id: ids
            }).then(function (res) {
              _this9.$notify({
                message: '删除成功',
                type: 'success'
              });
              _this9.$nextTick(function () {
                //获取列表方法
                _this9.GetTList();
              });
            });
          }).catch(function () {});
        } else {
          this.$message('只能删除一个');
        }
      } else {
        this.$message('请选中删除项');
      }
    },
    //查询
    SelecthandleChange: function SelecthandleChange(value) {
      var _this10 = this;
      this.follow_type = value;
      this.$nextTick(function () {
        //获取列表方法
        _this10.GetTList();
      });
    },
    //移交
    handleAgentChange: function handleAgentChange() {
      if (this.Alltableoptions.length !== 0) {
        this.staffSelectVisible = true;
        this.radio = true;
      } else {
        this.$message('请选择移交项');
      }
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      var _this11 = this;
      var new_uid = result.toString();
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认移交该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var ids = _this11.Alltableoptions.map(function (item) {
            return item.id;
          }).join();
          TransferTeacher({
            ids: ids,
            new_uid: new_uid
          }).then(function (res) {
            _this11.$notify({
              message: '移交成功',
              type: 'success'
            });
            _this11.$nextTick(function () {
              //获取列表方法
              _this11.GetTList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中移交项');
      }
    },
    //选中操作
    selsChange: function selsChange(val) {
      this.multipleSelection = val;
      this.Alltableoptions = val;
      // this.editForm = Object.assign({}, this.Alltableoptions[0]);

      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      // if (val.length > 1) {
      //     this.$refs.tb.clearSelection();
      //     this.$refs.tb.toggleRowSelection(val.pop());
      // }
    },
    //点击上传附件
    uploadSectionFile: function uploadSectionFile(param) {
      var _this12 = this;
      AttachmentW(param).then(function (res) {
        _this12.editForm.file_id = res.data.id;
      });
    },
    //图片上传
    Imageup: function Imageup(param) {
      var _this13 = this;
      // let newfile = new FormData();
      // newfile.append('file', param.file);
      // newfile.append('type', '200002');
      AttachmentS(param).then(function (res) {
        _this13.imageUrl = process.env.VUE_APP_BASE_API2 + res.data.path;
        _this13.editForm.img_id = res.data.id;
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 和 Png 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 3MB!');
        return false;
      }
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    //下载师资信息导入模板
    DownExcel: function DownExcel() {
      var _this14 = this;
      axios.defaults.headers.common['token'] = getTokens();
      axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      axios.post('https://crmapi.ternarysystem.com/clientserver.php/Teacher/downloadStandardExcel', {
        responseType: 'blob'
      }, {
        responseType: 'blob'
      }).then(function (res) {
        _this14.downloadExcel(res, '师资信息导入模板.xlsx');
        location.reload();
      });
    },
    //师资信息导入ok
    TeacherExcel: function TeacherExcel(param) {
      var _this15 = this;
      var newfile = new FormData();
      newfile.append('file', param.file);
      importTeacherExcel(newfile).then(function (res) {
        _this15.$notify({
          message: '成功',
          type: 'success'
        });
        _this15.$nextTick(function () {
          _this15.GetTList();
        });
      });
    },
    //师资详情
    goTodetails: function goTodetails(row) {
      var routeUrl = this.$router.resolve({
        path: '/myteacherDetail',
        query: {
          id: row.id
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    //新建跟进记录
    FollowupBtn: function FollowupBtn(data) {
      var _this16 = this;
      this.followUpDialog = true;
      //  Object.keys(this.temp).forEach(key => (this.temp[key] = ''))
      this.tempFollowup.t_name = data.name;
      this.tempFollowup.tid = data.id;
      TeacherFollow({
        tid: data.id
      }).then(function (res) {
        _this16.typeOptionsArr = res.data.teacher_follow_type;
        _this16.follows_up = res.data.follow_list;
      });
    },
    addFollowUp: function addFollowUp() {
      var _this17 = this;
      addTeacherFollow(this.tempFollowup).then(function (res) {
        _this17.$notify({
          message: '提交成功',
          type: 'success'
        });
        _this17.followUpDialog = false;
        _this17.$nextTick(function () {
          _this17.GetTList();
        });
        Object.keys(_this17.tempFollowup).forEach(function (key) {
          return _this17.tempFollowup[key] = '';
        });
      });
    },
    // 设置滚动条相关信息
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.tb.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    }
  }
};